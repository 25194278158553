import { useRuntimeConfig } from '@/6_shared/config'

class YandexMetrikaHelper {
  private key?: number

  constructor() {
    const { VITE_APP_YANDEX_METRIKA_KEY: key } = useRuntimeConfig()

    this.key = key
  }

  public trackEvent(params: any) {
    if (!this.key) return

    window.ym(this.key, 'reachGoal', params)
  }

  public trackPageChange() {
    if (!this.key) return

    window.ym(this.key, 'hit')
  }
}

export const yandexMetrika = new YandexMetrikaHelper()
