import type { RouteRecordRaw } from 'vue-router'

export const EventViewRoute: RouteRecordRaw = {
  path: '/event/:id',
  name: 'EventView',
  component: () => import('../ui/EventView.vue'),
  meta: {
    layout: 'empty'
  }
}
