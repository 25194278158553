<template>
  <ul class="flex flex-col gap-2">
    <LocationItem
      v-for="location in value"
      :key="location.id"
      :value="location"
      :current-location="currentLocation"
      @click="emit('click', location)"
    />
  </ul>
</template>

<script lang="ts" setup>
import type { Location } from '../model'
import LocationItem from './LocationItem.vue'

type PropType = {
  value: Location[]
  currentLocation?: Location
}

type EmitType = {
  (e: 'click', location: Location): void
}

withDefaults(defineProps<PropType>(), {
  currentLocation: undefined
})

const emit = defineEmits<EmitType>()
</script>
