import type { RouteRecordRaw } from 'vue-router'

export const VenueRepertoireRoute: RouteRecordRaw = {
  path: '/venue/:id/repertoire',
  name: 'VenueRepertoireView',
  component: () => import('../ui/VenueRepertoireView.vue'),
  meta: {
    layout: 'empty'
  }
}
