import { storeToRefs } from 'pinia'
import { partnerKey, useConfiguration } from '@/5_entities/Configuration'
import { transformAgeInterval, transformBirthDate, transformSex } from '../lib'
import type { User, UserAgeInterval, UserDTO, UserSex } from './type'

export class UserAdapter implements User {
  public userId: User['userId']
  public sex: User['sex']
  public ageInterval: User['ageInterval']
  public birthDate: User['birthDate']

  constructor(obj: UserDTO) {
    this.userId = obj.userId
    this.sex = (obj.sex as UserSex) ?? 1
    this.ageInterval = this._getAgeInterval(obj.ageInterval)
    this.birthDate = obj.birthDate
  }

  private _getAgeInterval(ageInterval: UserDTO['ageInterval']): UserAgeInterval {
    const { userAgeIntervalsList } = storeToRefs(useConfiguration())

    return transformAgeInterval(userAgeIntervalsList.value, ageInterval)
  }
}

export class UserDTOAdapter implements UserDTO {
  public userId: UserDTO['userId']
  public location?: UserDTO['location']
  public sex?: UserSex
  public ageInterval?: UserDTO['ageInterval']
  public partnerKey: UserDTO['partnerKey']
  public birthDate: UserDTO['birthDate']
  public coords: UserDTO['coords']

  constructor(obj: UserDTO) {
    this.userId = obj.userId
    this.location = obj?.location
    this.sex = this._getSex(obj.sex)
    this.ageInterval = this._getAgeInterval(obj?.ageInterval)
    this.partnerKey = partnerKey.get()
    this.birthDate = obj?.birthDate || this._getUserBirthDate(obj.ageInterval)
    this.coords = obj?.coords || ''
  }

  private _getSex(sex: any) {
    return transformSex(sex)
  }

  private _getAgeInterval(ageInterval: any): UserAgeInterval {
    const { userAgeIntervalsList } = storeToRefs(useConfiguration())

    return transformAgeInterval(userAgeIntervalsList.value, ageInterval)
  }

  private _getUserBirthDate(ageInterval: any): number | undefined {
    return transformBirthDate(ageInterval)
  }
}
