<template>
  <div v-if="!appIsReady">
    <ConfigureApp @configuration-initialized="configurationIsInitialized = true" />
    <AuthenticateUser
      v-if="configurationIsInitialized"
      @user-initialized="userIsInitialized = true"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeMount, watch, ref } from 'vue'
import { AuthenticateUser, ConfigureApp } from '@/4_features/Configuration'
import { useConfiguration, disableGlobalLoader } from '@/5_entities/Configuration'
import { appMessenger, lockScroll, unlockScroll } from '@/6_shared/lib'

const userIsInitialized = ref(false)
const configurationIsInitialized = ref(false)

const appIsReady = computed(() => userIsInitialized.value && configurationIsInitialized.value)

const { initApp } = useConfiguration()

onBeforeMount(() => lockScroll())
watch(
  () => appIsReady.value,
  (val) => {
    disableGlobalLoader()
    if (val) unlockScroll()
    initApp()
    appMessenger.open()
  }
)
</script>
