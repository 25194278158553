import { EventAdapter, type EventDTO } from '@/5_entities/Event'
import type { SearchResult } from '@/6_shared/model'
import type { Venue, VenueDTO } from './type'

export class VenueAdapter implements Venue {
  public id: Venue['id']
  public title: Venue['title']
  public address: Venue['address']
  public image: Venue['image']
  public coords: Venue['coords']
  public description: Venue['description']
  public category: Venue['category']
  public repertoire: Venue['repertoire']
  public repertoireEventsCount: Venue['repertoireEventsCount']
  public repertoirePagination: Venue['repertoirePagination']

  constructor(venue: VenueDTO) {
    this.id = venue.id
    this.title = venue.title
    this.address = venue?.address
    this.image = venue.media?.posterImage
    this.coords = venue.address?.coords
    this.description = venue?.fullDescription
    this.category = venue?.category
    this.repertoire = Array.isArray(venue.repertoire?.items) ? this._setRepertoire(venue) : []
    this.repertoireEventsCount = venue.repertoire?.pagination.totalCount || 0
    this.repertoirePagination = venue?.repertoire?.pagination
  }

  private _setRepertoire(venue: VenueDTO) {
    return venue.repertoire?.items.map((item) => new EventAdapter(item.object)) ?? []
  }

  public addRepertoireNextPage(newRepertoire: SearchResult<'event', EventDTO>) {
    const { pagination, items } = newRepertoire

    this.repertoirePagination = pagination
    const events = items.map((item) => new EventAdapter(item.object)) ?? []
    events.forEach((event) => this.repertoire.push(event))
  }
}
