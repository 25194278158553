import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import { getMilliseconds } from '@/6_shared/lib'
import type { SearchResultsDTO, SearchResultItemDTO } from '../model'
import { searchErrorsHandbook } from './error'

class SearchService extends BaseService {
  private readonly _endpoint = '/search'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public getRecommendations() {
    return this._http.get<SearchResultItemDTO>([this._endpoint, 'recommendations'].join('/'), {
      cacheConfig: {
        lifeTime: getMilliseconds.inHours(1)
      }
    })
  }

  public search(searchString: string) {
    return this._http.get<SearchResultsDTO>([this._endpoint].join('/'), {
      params: {
        textQuery: searchString
      }
    })
  }
}

const searchService = new SearchService(HttpService, searchErrorsHandbook)

export { searchService }
