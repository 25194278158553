<template>
  <UiIcon v-if="!isExternalIcon && !failedToLoadExternalIcon" v-bind="props" />
  <div v-else v-html="externalSvgIcon" />
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { computed, onBeforeMount, ref } from 'vue'
import { UiIcon } from '@/6_shared/ui'
import { configurationService } from '../api'
import { useConfiguration } from '../model'

type PropType = {
  name: string
  outline?: boolean
  solid?: boolean
}
const props = withDefaults(defineProps<PropType>(), {
  outline: true,
  solid: false
})

const { themePrefix: iconsThemePrefix } = storeToRefs(useConfiguration())

const isExternalIcon = computed(() => !!iconsThemePrefix.value)

//TODO: описать правильный урл до иконок на CDN
const iconHref = computed(() => {
  const prefix = props.solid ? 'solid' : props.outline ? 'outline' : ''

  return !isExternalIcon.value
    ? `#icon-${prefix}-${props.name}`
    : `/api/cdn/icon-${iconsThemePrefix.value}-${prefix}-${props.name}`
})

const externalSvgIcon = ref('')
const failedToLoadExternalIcon = ref(false)

onBeforeMount(async () => {
  if (!isExternalIcon.value) return

  try {
    externalSvgIcon.value = await configurationService.getIconUrl(iconHref.value)
  } catch (error: any) {
    failedToLoadExternalIcon.value = true
  }
})
</script>
