import type { RouteRecordRaw } from 'vue-router'

export const InternalServerErrorRoute: RouteRecordRaw = {
  name: 'InternalServerErrorView',
  path: '/500',
  component: () => import('../ui/InternalServerErrorView.vue'),
  meta: {
    layout: 'empty'
  }
}
