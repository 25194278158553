import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import { getMilliseconds } from '@/6_shared/lib'
import type { Theme } from '../model/theme/types'
import type { ConfigurationDTO } from '../model/types'
import { configurationErrorsHandbook } from './error'

class ConfigurationService extends BaseService {
  private readonly _endpoint = 'app'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public get(partnerKey: string) {
    return this._http.get<ConfigurationDTO>([this._endpoint, 'init'].join('/'), {
      params: {
        partnerKey
      },
      retry: 3,
      retryDelay: getMilliseconds.inSeconds(1)
    })
  }

  public uploadExternalThemeConfig = (url: string) => {
    return this._http.get<Theme>(url)
  }

  public getIconUrl(iconUrl: string) {
    return this._http.get<string>(iconUrl, {
      headers: {
        'Content-Type': 'image/svg+xml'
      }
    })
  }
}

const configurationService = new ConfigurationService(HttpService, configurationErrorsHandbook)

export { configurationService }
