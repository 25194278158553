import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import { getMilliseconds } from '@/6_shared/lib'
import { type UserDTO } from '../model'
import { userErrorsHandbook } from './error'

class UserService extends BaseService {
  private readonly _endpoint = '/user'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public getUser(user: UserDTO) {
    return this._http.post<UserDTO>(['app', 'auth'].join('/'), {
      body: { ...user },
      retry: 3,
      retryDelay: getMilliseconds.inSeconds(1),
      cacheConfig: {
        lifeTime: getMilliseconds.inHours(1)
      },
      timeout: getMilliseconds.inSeconds(5)
    })
  }

  public updateUser(user: UserDTO) {
    return this._http.put<UserDTO>([this._endpoint].join('/'), {
      body: { ...user },
      clearDataOnRequest: true
    })
  }
}

export const userService = new UserService(HttpService, userErrorsHandbook)
