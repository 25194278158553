<template>
  <main>
    <AppConfigError message="Не удалось инициализировать приложение. Не задан ключ партнёра" />
  </main>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { AppConfigError } from '@/3_widgets/Configuration'
import { disableGlobalLoader, partnerKey } from '@/5_entities/Configuration'

onMounted(() => {
  disableGlobalLoader()
  partnerKey.clear()
})
</script>
