import cloneDeep from 'lodash/cloneDeep.js'
import isPlainObject from 'lodash/isPlainObject.js'
// eslint-disable-next-line
import { flattenObject } from '../../../../6_shared/lib/utils/flattenObject'
import { themes } from '../../config'
import type { MappedTheme, Theme } from './types'

export class ThemeConstructor {
  private readonly _defaultTheme

  constructor(defaultTheme: Theme) {
    this._defaultTheme = defaultTheme
  }

  /**
   * отбрасывает лишние свойства в теме
   * @param theme
   */
  private _getGuardedTheme = (theme: Theme) => {
    const guardTheme: Theme = { ...theme }

    Object.keys(guardTheme).forEach((key) => {
      if (!(key in this._defaultTheme)) delete guardTheme[<keyof Theme>key]
    })

    return guardTheme
  }

  /**
   * возвращает объект вида
   * {css-variable-name}: value
   * @param theme
   * @private
   */
  private _mapTheme(theme: Theme): MappedTheme {
    return flattenObject<Theme>(this._getGuardedTheme(theme)) as MappedTheme
  }

  /**
   * устанавливает новые css переменные
   * @param themeObject
   * @private
   */
  private _setCssVariables(themeObject: MappedTheme) {
    const root = document.documentElement

    const setValue = (property: string, propertyValue: string) => {
      root.style.setProperty(`--${property}`, propertyValue)
    }

    Object.keys(themeObject).forEach((property) => {
      const value = themeObject[property]
      setValue(property, value)
    })
  }

  /**
   * создает из дефолтной темы объект с css переменными
   * {
   *   colors: {
   *     button: {
   *       primary: '#fff'
   *     }
   *   }
   * }
   * превратится в
   * {
   *   colors: {
   *     button: {
   *       primary: 'var(--colors-button-primary)'
   *     }
   *   }
   * }
   * @param object
   * @param sharedKey
   * @private
   */
  private _mapTailwindTemplateThemeVariables(object: any, sharedKey = '-') {
    Object.entries(object).forEach(([key, value]) => {
      const propertyKey = `${sharedKey}-${key}`

      if (isPlainObject(value)) {
        this._mapTailwindTemplateThemeVariables(value, propertyKey)
      } else {
        object[key] = `var(${propertyKey})`
      }
    })
  }

  /**
   * возврашает значения для tailwind
   */
  public get tailwindTheme() {
    const themeCopy = cloneDeep<Theme>(this._defaultTheme)

    this._mapTailwindTemplateThemeVariables(themeCopy)

    return {
      theme: { extend: themeCopy }
    }
  }

  /**
   * устанавливает дефолтную тему
   */
  public setDefaultTheme() {
    this.setTheme(this._defaultTheme)
  }

  /**
   * меняет тему
   * @param theme
   */
  public setTheme(theme: Theme): void {
    const themeObject = this._mapTheme(theme)
    //TODO: предусмотреть механзим мержа дефолтной темы и загруженной
    this._setCssVariables(themeObject)
  }
}

const themeConstructor = new ThemeConstructor(themes.default)

export { themeConstructor }
