import { stringIsArray } from '@/6_shared/lib'
import { maleSexVariants } from '../config'
import { type UserDTO } from '../model'
import type { UserAgeInterval } from '../model'

export const transformAgeInterval = (
  ageIntervals: UserAgeInterval[],
  ageInterval: UserDTO['ageInterval']
): UserAgeInterval => {
  if (!Array.isArray(ageIntervals)) return [0, 0]
  let foundInterval = ageIntervals[ageIntervals.length - 1]

  const isArray = stringIsArray(ageInterval)

  if (!ageInterval) return foundInterval

  if (isArray) {
    const [startUserInterval, endUserInterval] = Array.isArray(ageInterval)
      ? ageInterval
      : (JSON.parse(String(ageInterval)) as [number, number])
    const userStartYear =
      String(startUserInterval).length < 4
        ? new Date().getFullYear() - startUserInterval
        : startUserInterval
    const userEndYear =
      String(endUserInterval).length < 4
        ? new Date().getFullYear() - endUserInterval
        : endUserInterval

    let minIntervalSum = 1000

    ageIntervals.forEach((interval) => {
      const [startInterval, endInterval] = interval
      const leftDelta = Math.abs(startInterval - userStartYear)
      const rightDelta = Math.abs(endInterval - userEndYear)
      const currentSum = leftDelta + rightDelta

      if (minIntervalSum > currentSum) {
        minIntervalSum = currentSum
        foundInterval = interval
      }
    })

    return foundInterval
  }

  if (typeof ageInterval === 'string' || typeof ageInterval === 'number') {
    const userBirthYear =
      String(ageInterval).length < 4
        ? new Date().getFullYear() - Number(ageInterval)
        : Number(ageInterval)

    ageIntervals.forEach((interval) => {
      const [start, end] = interval

      //попали в интервал
      if (start <= userBirthYear && end >= userBirthYear) {
        foundInterval = interval

        return
      }
    })

    return foundInterval
  }

  return foundInterval
}

export const transformSex = (sex: any) => {
  if (typeof sex === 'string') {
    return maleSexVariants.includes(sex) ? 1 : 0
  }

  if (typeof sex === 'number') {
    return sex
  }

  return 0
}

export const transformBirthDate = (ageInterval: UserDTO['ageInterval']) => {
  try {
    const isArray = stringIsArray(ageInterval)
    const isNotPrimitive = typeof ageInterval !== 'string' && typeof ageInterval !== 'number'
    if (isArray || isNotPrimitive) return undefined

    const value =
      String(ageInterval).length < 4
        ? new Date().getFullYear() - Number(ageInterval)
        : Number(ageInterval)

    return isNaN(value) ? undefined : value
  } catch {
    return undefined
  }
}
