<template>
  <slot />
</template>

<script lang="ts" setup>
import { onMounted } from 'vue'
import { useConfiguration } from '@/5_entities/Configuration'

const { initApp } = useConfiguration()
onMounted(() => initApp())
</script>
