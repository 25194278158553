import { defineStore } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { computed, ref } from 'vue'
import { usePageLoading } from '@/6_shared/composables'
import { searchService } from '../api'
import {
  type SearchResults,
  SearchResultsAdapter,
  type SearchResultsItem,
  SearchResultsItemAdapter
} from '../model'

export const useSearch = defineStore('search', () => {
  const { initialized, load } = usePageLoading()

  // recommendations
  const recommendations = ref<Undefinable<SearchResultsItem>>(undefined)
  const searchRecommendations = computed(
    () =>
      Array.isArray(recommendations.value) &&
      !!recommendations.value.length &&
      recommendations.value.filter((_, count) => count < 5)
  )

  const getRecommendations = async () => {
    load(
      async () => {
        const recommendationsDTO = await searchService.getRecommendations()

        recommendations.value = new SearchResultsItemAdapter(recommendationsDTO)
      },
      (error) => {
        console.error('failed to fetch recommendations ', error)
      }
    )
  }

  // search
  const searchString = ref<string>('')
  const searchIsPending = ref(false)
  const results = ref<Undefinable<SearchResults>>(undefined)
  const searchPage = ref(1)

  const resetSearchPage = () => {
    searchPage.value = 1
  }

  const search = async (searchString: string) => {
    resetSearchPage()

    if (!searchString) return

    try {
      searchIsPending.value = true
      results.value = new SearchResultsAdapter(
        await searchService.search(searchString)
      ) as SearchResults
    } catch (error) {
      //TODO: обработать ошибки
      results.value = []
    } finally {
      searchIsPending.value = false
    }
  }

  const resetSearch = () => {
    resetSearchPage()
    searchString.value = ''
    results.value = undefined
  }

  //search category
  const searchCategoryPage = ref(1)

  return {
    initialized,
    searchString,
    results,
    searchRecommendations,
    searchIsPending,
    searchPage,
    searchCategoryPage,
    resetSearch,
    getRecommendations,
    search
  }
})
