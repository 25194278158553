import { EventAdapter, type EventDTO } from '@/5_entities/Event'
import type { SearchResult } from '@/6_shared/model'
import type { Category, CategoryDTO } from './type'

export class CategoryAdapter implements Category {
  public id: Category['id']
  public name: Category['name']
  public repertoire: Category['repertoire']
  public eventsPagination: Category['eventsPagination']

  constructor(data: CategoryDTO) {
    this.id = data.id
    this.name = data.name
    this.repertoire = this._getEvents(data)
    this.eventsPagination = data?.repertoire?.pagination
  }

  private _getEvents(data: CategoryDTO) {
    return data.repertoire?.items.map((item) => new EventAdapter(item.object)) ?? []
  }

  public addEventsNextPage(newEvents: SearchResult<'event', EventDTO>) {
    const { pagination, items } = newEvents
    this.eventsPagination = pagination
    const events = items.map((item) => new EventAdapter(item.object))
    events.forEach((event) => this.repertoire.push(event))
  }
}
