import type { EventDTO } from '@/5_entities/Event'
import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import type { SearchResult } from '@/6_shared/model'
import { type VenueDTO } from '../model'
import { venueErrorsHandbook } from './error'

class VenueService extends BaseService {
  private readonly _endpoint: string = 'venues'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public get(id: number) {
    return this._http.get<VenueDTO>([this._endpoint, id].join('/'), {
      generalUrl: 'venues:id',
      needCancelOnRouteChange: true,
      appMessageErrors: [404]
    })
  }

  public loadRepertoire(venueId: number, pageNumber: number) {
    return this._http.get<SearchResult<'event', EventDTO>>(
      [this._endpoint, venueId, 'events'].join('/'),
      {
        generalUrl: 'venues:id:repertoire',
        needCancelOnRouteChange: true,
        query: {
          pageNumber,
          pageSize: 10
        }
      }
    )
  }
}

const venueService = new VenueService(HttpService, venueErrorsHandbook)

export { venueService }
