import type { RouteRecordRaw } from 'vue-router'
import ConfigurationErrorView from '../ui/ConfigurationErrorView.vue'

export const ConfigurationErrorRoute: RouteRecordRaw = {
  path: '/errors/partnerId',
  name: 'ConfigurationErrorView',
  component: ConfigurationErrorView,
  meta: {
    layout: 'error'
  }
}
