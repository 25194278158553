import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import type { Seance } from '../model'
import { seanceErrorsHandbook } from './error'

export * from './error'

class SeanceService extends BaseService {
  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  public getWidget(seanceId: Seance['id']) {
    return this._http.get<{ url: string }>(['seance', seanceId, 'widget'].join('/'), {
      generalUrl: 'seance.widget:id',
      needCancelOnRouteChange: true,
      appMessageErrors: [404]
    })
  }
}

const seanceService = new SeanceService(HttpService, seanceErrorsHandbook)

export { seanceService }
