<template>
  <li>
    <UiButton
      :class="{ '!text-text-primary': isActiveLocation }"
      text
      class="flex w-full items-center justify-between !px-4 !py-2.5 text-left"
    >
      {{ getLocationName(value) }}
      <ThemeIcon v-if="isActiveLocation" name="check" class="size-5" />
    </UiButton>
  </li>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { ThemeIcon } from '@/5_entities/Configuration'
import { UiButton } from '@/6_shared/ui'
import { getLocationName } from '../lib'
import type { Location } from '../model'

type PropType = {
  value: Location
  currentLocation?: Location
}

const props = withDefaults(defineProps<PropType>(), {
  currentLocation: undefined
})

const isActiveLocation = computed(
  () => props.currentLocation && props.currentLocation.id === props.value.id
)
</script>
