<template>
  <UiLoader class="size-screen fixed inset-0 z-50 bg-primary" />
</template>

<script setup lang="ts">
import { onBeforeMount, onUnmounted } from 'vue'
import { lockScroll, unlockScroll } from '@/6_shared/lib'
import { UiLoader } from '@/6_shared/ui'

onBeforeMount(() => lockScroll())
onUnmounted(() => unlockScroll())
</script>
