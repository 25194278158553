<template>
  <div />
</template>

<script lang="ts" setup>
import { onBeforeMount } from 'vue'
import { getParsedInitialParamsFromQuery } from '@/5_entities/Configuration'
import { useLocation, type Location } from '@/5_entities/Location'
import { type UserDTO, UserDTOAdapter, useUser } from '@/5_entities/User'

type EmitType = {
  (e: 'userInitialized'): void
}

const emit = defineEmits<EmitType>()

const { getUser } = useUser()

const { setLocationOnInit } = useLocation()

const getUserFromQuery = () => {
  const query = getParsedInitialParamsFromQuery()

  return new UserDTOAdapter({
    userId: query.userId as string,
    location: query?.location as string,
    ageInterval: query?.ageInterval as string,
    sex: query?.sex as string,
    coords: query?.coords as string
  })
}

const setLocation = (userData: UserDTO) => {
  const { location, adjustLocation } = userData

  setLocationOnInit(location as Location, adjustLocation)
}

onBeforeMount(async () => {
  const userDTO = getUserFromQuery()
  const userData = await getUser(userDTO)

  if (!userData) return emit('userInitialized')

  setLocation(userData)
  emit('userInitialized')
})
</script>
