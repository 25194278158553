import type { RouteRecordRaw } from 'vue-router'
import { useSearch } from '@/5_entities/Search'

export const SearchViewRoute: RouteRecordRaw = {
  path: '/search',
  name: 'SearchView',
  component: () => import('../ui/SearchView.vue'),
  beforeEnter: (_to, from, next) => {
    const needSaveSearch = !!from.meta?.saveSearch

    if (!needSaveSearch) useSearch().resetSearch()

    next()
  }
}
