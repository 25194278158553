import { BaseService, type ErrorsHandbook, HttpService } from '@/6_shared/api'
import type { CategoryDTO, CategoryFilters } from '../model'
import { categoryErrorsHandbook } from './error'

class CategoryService extends BaseService {
  private readonly _endpoint: string = 'categories'

  constructor(http: typeof HttpService, errorsHandbook: ErrorsHandbook) {
    super(http, errorsHandbook)
  }

  private _modifyFilters(filters?: CategoryFilters) {
    if (!filters) return

    const filtersCopy = { ...filters }
    // добавляем dateTo, если выбарана только dateFrom
    if (filtersCopy.dateFrom && !filtersCopy.dateTo) filtersCopy.dateTo = filtersCopy.dateFrom

    return filtersCopy
  }

  public get(id: number, filters?: CategoryFilters) {
    return this._http.get<CategoryDTO>([this._endpoint, id].join('/'), {
      generalUrl: 'category:id',
      needCancelOnRouteChange: true,
      query: {
        ...this._modifyFilters(filters),
        pageSize: filters?.pageSize ?? 5
      },
      appMessageErrors: [404]
    })
  }

  public loadEvents(categoryId: number, pageNumber: number, filters?: CategoryFilters) {
    filters && this._modifyFilters(filters)

    return this._http.get<CategoryDTO>([this._endpoint, categoryId].join('/'), {
      generalUrl: 'category:id.events',
      needCancelOnRouteChange: true,
      query: {
        pageNumber,
        pageSize: 10,
        ...this._modifyFilters(filters),
      }
    })
  }

  public getAll(filters?: CategoryFilters) {
    filters && this._modifyFilters(filters)

    return this._http.get<CategoryDTO[]>(this._endpoint, {
      needCancelOnRouteChange: true,
      query: {
        ...this._modifyFilters(filters),
        pageSize: filters?.pageSize ?? 5
      }
    })
  }
}

const categoryService = new CategoryService(HttpService, categoryErrorsHandbook)

export { categoryService }
