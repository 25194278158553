import type { RouteRecordRaw } from 'vue-router'

export const NotFondRoute: RouteRecordRaw = {
  path: '/404',
  name: 'NotFoundView',
  component: () => import('../ui/NotFoundView.vue'),
  meta: {
    layout: 'empty'
  }
}

export const NotFoundRedirectRoutes: RouteRecordRaw = {
  path: '/:catchAll(.*)',
  redirect: NotFondRoute
}
