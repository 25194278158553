import { useCookies } from '@vueuse/integrations/useCookies'
import { parseQuery } from 'ufo'
import type { CookieSetOptions } from 'universal-cookie/es6/types'
import { CookiesName, getElementByGlobalId, GlobalIds } from '@/6_shared/config'

const partnerKeyCookieParams: CookieSetOptions = {
  path: '/',
  sameSite: 'lax',
  secure: true
}

export const partnerKey = {
  get(): string {
    const cookies = useCookies()
    const partnerKey = cookies.get(CookiesName.partnerKey)

    return typeof partnerKey === 'number' ? String(partnerKey) : partnerKey
  },
  set(partnerKey: string) {
    const cookies = useCookies()

    cookies.set(CookiesName.partnerKey, partnerKey, partnerKeyCookieParams)
  },
  clear() {
    const cookies = useCookies()

    cookies.remove(CookiesName.partnerKey, partnerKeyCookieParams)
  },
  handleEmpty() {
    location.replace('/errors/partnerId')
  }
}

export const disableGlobalLoader = () => {
  getElementByGlobalId(GlobalIds.globalAppLoader)!.style.display = 'none'
}

export const getParsedInitialParamsFromQuery = () => parseQuery(location.search)
