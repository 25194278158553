import { useCookies } from '@vueuse/integrations/useCookies'
import { defineStore } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { ref, computed } from 'vue'
import { CookiesName } from '@/6_shared/config'
import { useConfiguration } from '../../Configuration'
import { type Location, useLocation } from '../../Location'
import { userService } from '../api'
import {
  UserAdapter,
  type UserDTO,
  type User,
  type UserAgeInterval,
  UserDTOAdapter
} from '../model'

export const useUser = defineStore('user', () => {
  // user
  const defaultUser = new UserAdapter({ userId: '' })
  const user = ref<Undefinable<User>>(defaultUser)

  const getUser = async (userData: UserDTO) => {
    try {
      const userDTO = await userService.getUser(userData)

      setUser(userDTO)

      return userDTO
    } catch (error) {
      setDefaultUser()
      console.log('error to fetch user ', error)
    }
  }

  const setUserAuthToken = (token: string) => {
    const cookies = useCookies()
    cookies.set(CookiesName.authToken, token, {
      sameSite: 'lax',
      path: '/',
      secure: true
    })
  }

  const setDefaultUser = () => {
    user.value = defaultUser
  }

  const setUser = (userDTO: UserDTO) => {
    user.value = new UserAdapter(userDTO)
    userDTO.authToken && setUserAuthToken(userDTO.authToken)
  }

  const changeUserProp = <T extends keyof User>(prop: T, value: User[T]) => {
    user.value && (user.value[prop] = value)
  }

  const updateUser = async (data?: Partial<UserDTO>, updatePage: boolean = true) => {
    const { updateAppOnCriticalParamsChange } = useConfiguration()

    try {
      const { currentLocation } = useLocation()

      if (!user.value) return
      const userDTO = new UserDTOAdapter({
        ...user.value,
        location: currentLocation!.id,
        ...data
      })

      if (updatePage) {
        await updateAppOnCriticalParamsChange<UserDTO>(userService.updateUser(userDTO))
      } else {
        await userService.updateUser(userDTO)
      }
    } catch (error) {
      console.log('error to update user ', error)
    }
  }

  //sex
  const userSex = computed(() => user.value && user.value.sex)
  const updateUserSex = (sex: User['sex']) => {
    changeUserProp<'sex'>('sex', sex)

    updateUser()
  }

  //age interval
  const userSelectedAgeInterval = computed(() => user.value && user.value.ageInterval)
  const updateUserAgeInterval = (interval: UserAgeInterval) => {
    changeUserProp<'ageInterval'>('ageInterval', interval)

    updateUser()
  }

  //location
  const updateUserLocation = (location: Location, updatePage?: boolean) => {
    updateUser({ location: location.id, adjustLocation: false }, updatePage)
  }

  return {
    //base
    user,
    getUser,
    updateUser,
    //sex
    userSex,
    updateUserSex,
    // age interval
    userSelectedAgeInterval,
    updateUserAgeInterval,
    // location
    updateUserLocation
  }
})
