import type { RouteRecordRaw } from 'vue-router'

export const VenueViewRoute: RouteRecordRaw = {
  path: '/venue/:id',
  name: 'VenueView',
  component: () => import('../ui/VenueView.vue'),
  meta: {
    layout: 'empty'
  }
}
