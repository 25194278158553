export * from './theme'

import type { UserAgeInterval } from '../../User'

export const userAgeIntervalsDefault: { name: string; interval: UserAgeInterval }[] = [
  {
    name: 'Бэби бумеры',
    interval: [1944, 1967]
  },
  {
    name: 'Икс',
    interval: [1967, 1984]
  },
  {
    name: 'Миллениалы',
    interval: [1984, 2000]
  },
  {
    name: 'Зумеры',
    interval: [2000, 2015]
  },
  {
    name: 'Альфа',
    interval: [2011, new Date().getFullYear()]
  }
]
