import { type Event, EventAdapter, type EventDTO } from '@/5_entities/Event'
import { type Venue, VenueAdapter, type VenueDTO } from '@/5_entities/Venue'
import type { SearchResultsDTO, SearchResultsItem, SearchResultItemDTO } from './type'

export class SearchResultsItemAdapter implements SearchResultsItem {
  public id: SearchResultsItem['id']
  public name: SearchResultsItem['name']
  public rank: SearchResultsItem['rank']
  public repertoirePagination: SearchResultsItem['repertoirePagination']
  public repertoire: SearchResultsItem['repertoire']

  constructor(data: SearchResultItemDTO) {
    this.id = data.id
    this.name = data.name
    this.rank = data.rank
    this.repertoirePagination = data.repertoire.pagination
    this.repertoire = data.repertoire.items.map((item) => {
      if (item.type === 'event') {
        return {
          type: 'event',
          object: new EventAdapter(item.object as EventDTO) as Event
        }
      }

      return {
        type: 'venue',
        object: new VenueAdapter(item.object as VenueDTO) as Venue
      }
    })
  }
}

export class SearchResultsAdapter {
  constructor(data: SearchResultsDTO) {
    const results = data
      .map((dto) => {
        return new SearchResultsItemAdapter(dto)
      })
      .filter((item) => item.repertoire.length) as SearchResultsItem[]

    results.sort((itemA, itemB) => itemB.rank - itemA.rank)

    return results
  }
}
