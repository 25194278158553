<template>
  <form @submit.prevent>
    <header class="mb-2.5 flex items-center">
      <GoBack v-if="!isPage" static class="mr-3" @click="emit('close')" />
      <UiTitle severity="h1">Город</UiTitle>
    </header>
    <UiInput
      :model-value="modelValue"
      type="search"
      maxlength="50"
      class="pl-10"
      placeholder="Поиск по городам"
      @update:model-value="emit('update:modelValue', $event)"
    >
      <template #prepend>
        <ThemeIcon
          name="magnifying-glass"
          class="absolute left-5 size-4 h-full text-text-fivefold"
        />
      </template>
    </UiInput>
  </form>
</template>

<script lang="ts" setup>
import { GoBack } from '@/4_features/Navigation'
import { ThemeIcon } from '@/5_entities/Configuration'
import { UiInput, UiTitle } from '@/6_shared/ui'

type PropType = {
  modelValue: string
  isPage: boolean
}

type EmitType = {
  (e: 'update:modelValue', value: string): void
  (e: 'close'): void
}

defineProps<PropType>()

const emit = defineEmits<EmitType>()
</script>
