import { defineStore } from 'pinia'
import type { Undefinable } from 'ts-helpers'
import { computed, ref } from 'vue'
import { usePageLoading } from '@/6_shared/composables'
import type { Pagination } from '@/6_shared/model'
import { venueService } from '../api'
import { type Venue, VenueAdapter, type VenueDTO } from '../model'

export const useVenue = defineStore('venue', () => {
  const venue = ref<Undefinable<Venue>>(undefined)
  const setVenue = (venueData: VenueDTO) => (venue.value = new VenueAdapter(venueData))

  const { initialized, load } = usePageLoading()
  const getVenue = async (id: number) => {
    load(async () => {
      const venue = await venueService.get(id)
      setVenue(venue)
    })
  }

  //repertoire
  const pagination = computed((): Undefinable<Pagination> => venue.value?.repertoirePagination)
  const allRepertoireLoaded = computed(
    () => !!pagination.value && pagination.value.currentPage >= pagination.value.pagesCount
  )

  const loadNextRepertoirePage = async () => {
    if (!venue.value || !pagination.value) return

    const newEvents = await venueService.loadRepertoire(
      venue.value.id,
      pagination.value.currentPage + 1
    )

    venue.value.addRepertoireNextPage && venue.value.addRepertoireNextPage(newEvents)
  }

  return {
    initialized,
    venue,
    getVenue,
    //repertoire
    pagination,
    loadNextRepertoirePage,
    allRepertoireLoaded
  }
})
