<template>
  <div class="bg-red-500">
    <div class="container m-auto flex h-svh items-center justify-center p-4">
      <div
        class="bg-error-200 font-mono flex items-center gap-x-3 rounded-xl border bg-red-100 px-6 py-4 text-red-700"
      >
        <span class="size-5 bg-error">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
            />
          </svg>
        </span>
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { disableGlobalLoader } from '@/5_entities/Configuration'
import { appMessenger } from '@/6_shared/lib'

defineProps({
  message: {
    type: String,
    required: true,
    default: ''
  }
})

onMounted(() => {
  disableGlobalLoader()
  appMessenger.error.integration.partnerKey()
})
</script>
